<div class="overflow-container" #container>
  <ng-container *ngIf="isCommunity && activeWorkspace && !workspaceNeutral">
    <span class="workspace">{{activeWorkspace.name}}</span>
    <i class="al-icon al-ico-slash"></i>
  </ng-container>
  <ng-container *ngFor="let breadcrumbGroup of breadcrumbs ; let lastGroup=last; let
  firstGroup=first">
    <ng-container *ngIf="!(shouldCollapse && breadcrumbGroup?.length > 0 && breadcrumbGroup?.[0]?.collapsable); else
    collapseMenu">
      <ng-container *ngFor="let breadcrumb of breadcrumbGroup ; let
      lastCrumb=last; let firstCrumb=first">
        <span class="sub-projects-breadcrumbs">
                    <i *ngIf="breadcrumb.hidden" class="al-icon al-ico-ghost sm me-1 align-ghost"></i>
                    <a *ngIf="breadcrumb.url && !(lastCrumb && lastGroup); else noURL" class="crumb url"
                       [class.first]="firstCrumb && firstGroup"
                       [routerLink]="breadcrumb.url">
                      {{breadcrumb.name}}</a>
                    <i *ngIf="!(lastCrumb && lastGroup)" class="al-icon al-ico-slash"></i>
                </span>
        <ng-template #noURL>
                    <span class="crumb" [class.last]="lastGroup && lastCrumb" [class.first]="firstGroup && firstCrumb"
                    ><div class="ellipsis" [smTooltip]="breadcrumb.name"
                          smShowTooltipIfEllipsis>{{breadcrumb.name}} {{breadcrumb.example? ' (example)':''}}</div></span>
        </ng-template>
      </ng-container>
    </ng-container>
    <ng-template #collapseMenu>
      <i *ngIf="breadcrumbGroup?.length > 0" [matMenuTriggerFor]="subProjectsMenu"
         class="menu-trigger-icon al-icon msm pointer"
         [class.al-ico-d-menu-down]="!subProjectsMenuIsOpen"
         [class.al-ico-d-menu-up]="subProjectsMenuIsOpen" (menuOpened)="subProjectsMenuOpened(true)"
         (menuClosed)="subProjectsMenuOpened(false)"></i>
      <ng-container class="d-flex" *ngIf="breadcrumbGroup?.length>0">
        <i class="al-icon al-ico-slash"></i>
      </ng-container>
      <mat-menu #subProjectsMenu="matMenu">
                <span mat-menu-item
                      class="sub-project-menu-item"
                      [smTooltip]="breadcrumb.name"
                      smShowTooltipIfEllipsis
                      *ngFor="let breadcrumb of breadcrumbGroup"
                      [routerLink]="breadcrumb.url!=='projects/*/projects'? breadcrumb.url: 'projects/*'">
                  <i *ngIf="breadcrumb.hidden" class="al-icon al-ico-ghost sm me-1"></i>{{breadcrumb.name}}
                </span>
      </mat-menu>

    </ng-template>
  </ng-container>
  <div>
    <div
      #menuTrigger="matMenuTrigger"
      [matMenuTriggerFor]="shareModal"
      (menuOpened)="openShareModal()"
    >
      <i *ngIf="showShareButton && !isCommunity" class="fa fa-share-alt share pointer" smTooltip="Share"></i>
    </div>
    <mat-menu #shareModal="matMenu">
      <div class="share-menu-container" smClickStopPropagation>
        <div class="d-flex align-items-center justify-content-between top">
          <div class="share-title">SHARE VIEW</div>
          <i class="pointer al-icon al-ico-dialog-x al-color blue-300 sm-md close-dialog"
             (click)="menuTrigger.closeMenu()" smClickStopPropagation></i>
        </div>
        <div class="copy-title">Copy the following URL to share this view with others</div>

        <div class="share-link-container" [class.hidden]="!(currentUrl)">
          <div class="ellipsis share-link">
            {{currentUrl}}</div>
          <div ngxClipboard
               smClickStopPropagation
               [cbContent]="currentUrl"
               class="copy pointer"
               (cbOnSuccess)="copyToClipboardSuccess(); menuTrigger.closeMenu()">
            <i class="fas fa-copy "></i>
          </div>
        </div>
      </div>
    </mat-menu>
  </div>
  <div *ngIf="archive" data-id="Archive Label" class="archive"><i class="al-icon xs al-ico-archive me-1"></i>Archive
  </div>
</div>

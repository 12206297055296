<div class="filter-sort-container d-flex align-items-center" data-id="Detailed View Filter"
     [ngClass]="isFiltering ? 'active' : 'deactive'">
  <div class='cell pointer'>
    <i [class]="'icon ' + (isFiltering? 'i-filter-on':'i-filter-off')" [matMenuTriggerFor]="menu" data-id="filterIcon"></i>
  </div>
  <mat-menu #menu="matMenu" class="table-card-filter-menu" classList="light-theme">
    <ng-container *ngFor="let option of optionsFiltered; trackBy: trackByKey">
      <ng-template let-col
                   [ngTemplateOutletContext]="{$implicit: getColumnByOption(option)}"
                   [ngTemplateOutlet]="column" #column
      >
        <button mat-menu-item [matMenuTriggerFor]="subMenu" (menuOpened)="menuOpened.emit(col)">
          <i [class.hidden]="!isOptionFiltered(option.key)" class="filter-point icon i-v-black xs"></i> {{col?.header}}
        </button>
        <mat-menu #subMenu="matMenu" class="table-card-filter-menu light-theme" (close)="closeMenu(col)">
          <form class="filter-form">
            <mat-form-field appearance="fill" *ngIf="col?.searchableFilter" smClickStopPropagation class="tags-menu-input w-100 no-bottom">
              <input matInput (input)="setSearchTerm($event, option.key)" name="filter" [(ngModel)]="searchTerms[option.key]" autocomplete="off">
              <i matSuffix class="fa me-3" [class]="searchTerms[option.key] ? 'fa-times pointer' : 'fa-search'" (click)="searchTerms[option.key] && clearSearch(option.key)"></i>
            </mat-form-field>
          </form>
          <div *ngIf="col?.andFilter" class="filter-type" smClickStopPropagation>
          <span class="link me-3" [class.selected]="filterMatch[option.key] !== 'AND'"
                (click)="toggleCombination(option.key)">Any</span>
            <span class="link" [class.selected]="filterMatch[option.key] === 'AND'"
                  (click)="toggleCombination(option.key)">All</span>
          </div>
          <div class="results" [class.scroll]="col.searchableFilter" *ngIf="col" smClickStopPropagation>
             <sm-checkbox-three-state-list
               [supportExcludeFilter]="col.excludeFilter"
               [checkedList]="value[col.id]"
               [options]="option.value | filter : searchTerms[option.key]: 'label'"
               (filterChanged)="emitFilterChangedCheckBox(option.key, $event)"
             > </sm-checkbox-three-state-list>
          </div>
          <div class="fixed-options" *ngIf="option.key==='tags'">
            <mat-divider></mat-divider>
            <div *ngIf="subOptions?.length" class="fixed-options-subheader">
              {{fixedOptionsSubheader}}
            </div>
            <sm-menu-item
              *ngFor="let option of subOptions; trackBy: trackByLabel"
              [itemLabel]="option.label"
              [checked]="subValue?.includes(option.value)" [selectable]="true" [itemValue]="option.value"
              (itemClicked)="onSubFilterChanged({id :'system_tags'}, $event)"
            ></sm-menu-item>
          </div>
          <div *ngIf="col.columnExplain && optionsFiltered.length" class="column-explanation">{{col.columnExplain}}</div>
        </mat-menu>
      </ng-template>
    </ng-container>
    <hr>
    <div mat-menu-item class="clear-all mat-menu-item" (click)="isFiltering && clearAll.emit()" [disabled]="!isFiltering">Clear all</div>
  </mat-menu>
</div>


<sm-card class="model-card" (click)="modelClicked()" [isExample]="!model?.company?.id" [height]="264">
  <div header-content>
    <div class="d-flex justify-content-between">
      <div class="title me-auto">{{model?.name}}</div>
    </div>
    <div class="pt-2">
      <div class="d-flex align-items-center justify-content-start">
        <span class="sub-title">ID:  {{model?.id}} </span>
        <sm-copy-clipboard [clipboardText]="model?.id" [label]="''" [hideBackground]="true"></sm-copy-clipboard>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-around w-100">
    <sm-circle-status [status]="model.framework" defaultStatus="i-model"></sm-circle-status>
    <sm-circle-counter [counter]="model?.stats?.labels_count" label="LABELS" [type]="CircleTypeEnum['model-labels']"></sm-circle-counter>
    <sm-circle-status [status]="model.ready? 'published' : 'created'"></sm-circle-status>
  </div>


  <div footer class="brio-footer">
    <div class="model-footer">
      <div class="top-labels ellipsis" [smTooltip]="model?.task?.name">CREATOR EXPERIMENT:
          {{model?.task?.name}}
      </div>
      <div class="top-labels ellipsis d-flex justify-content-between">
        <span [smTooltip]="model.created | date : TIME_FORMAT_STRING">Created {{model.created | timeAgo}}</span>
        <span>{{model?.user?.name}}</span>
      </div>
    </div>
  </div>
</sm-card>

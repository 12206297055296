<div #drawHere
     [style.height.px]="height"
     [class.whitebg]="!isDarkTheme"
     [class.dark-theme]="isDarkTheme"
     [class.loading]="loading"
     [class.whitebg-table]="type === 'table'"
     [class.move-title]="type !== 'parcoords'"
     (scroll)="repositionModeBar($event.target)"
>
  <div #graphTitle class="graph-title ellipsis" [class.table-title]="type === 'table'" [title]="graphTitle.scrollWidth <= graphTitle.clientWidth ? '' : title" (mouseenter)="changeDetector.detectChanges()">{{title}}</div>
  <div *ngIf="loading" class="plot-loader">Loading...</div>
</div>

<mat-expansion-panel *ngFor="let iteration of iterations; let first = first; trackBy:trackKey"
                     class="images-section" [class.dark-theme]="isDarkTheme" togglePosition="before" [expanded]="first">
  <mat-expansion-panel-header class="debug-header" [collapsedHeight]="null" *ngIf="!isDatasetVersionPreview">
    <mat-panel-title> {{iteration.iter}}</mat-panel-title>
  </mat-expansion-panel-header>
  <ng-template matExpansionPanelContent>
    <div class="d-flex justify-content flex-wrap sample-row">
      <sm-debug-image-snippet
        *ngFor="let frame of iteration.events; trackBy:trackFrame"
        [frame]="frame"
        [theme]="isDarkTheme? themeEnum.Dark: themeEnum.Light"
        (imageError)="imageUrlError({frame, experimentId})"
        (imageClicked)="imageClicked.emit({frame})"
        (createEmbedCode)="createEmbedCode.emit({metrics: [frame.metric], variants: [frame.variant], domRect:$event});">
      </sm-debug-image-snippet>
    </div>
  </ng-template>
</mat-expansion-panel>

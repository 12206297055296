<div [matMenuTriggerFor]="pipelinesMenu" smClickStopPropagation
     class="p-0 cell menu-button al-icon al-color blue-300 al-ico-bars-menu"
></div>
<mat-menu #pipelinesMenu="matMenu">
  <button mat-menu-item (click)="rename.emit()">
    <i class="al-icon sm-md" [class]="icons.EDIT"></i>Rename
  </button>
  <button mat-menu-item [matMenuTriggerFor]="tagMenu">
    <i class="al-icon sm-md" [class]="icons.TAG"></i>Add Tag
  </button>
  <hr>
  <button mat-menu-item (click)="delete.emit()">
    <i class="al-icon sm-md" [class]="icons.REMOVE"></i>Delete
  </button>
</mat-menu>
<mat-menu #tagMenu="matMenu">
  <sm-tags-menu
    #tagMenuContent
    class="light-theme"
    [tags]="project?.tags"
    [tagsFilterByProject]="true"
    [projectTags]="allTags"
    [companyTags]="null"
    (tagSelected)="addTag.emit($event)">
  </sm-tags-menu>
</mat-menu>

<span class="search-input-container"
      [class.expand]="expandOnHover"
      [class.active]="active"
      [class.with-counter]="enableNavigation"
      [class.with-results]="searchResultsCount > 0 && !empty"
      [class.focused]="focused"
      (dblclick)="$event.stopPropagation()"
      (mouseover)="updateActive(true)"
      (mouseleave)="updateActive(false)">
  <span class="search-input" [class.expand]="expandOnHover">
  <input
    [class.active]="active"
    data-id="searchInputField"
    #searchBar
    [placeholder]="placeholder"
    (focusin)="focusInput(true)"
    (focusout)="focusInput(false)"
    (input)="onValueChange()"
    (keydown)="onKeyDown($event)"
  >
  <i *ngIf="!hideIcons && searchBar.value.length === 0; else hideIcons ? noIcon : showX"
     class="icon-button al-icon al-ico-search" data-id="searchIcon"></i>
    <ng-template #showX>
    <i class="icon-button al-icon al-ico-dialog-x pointer" (click)="clear()" data-id="searchCrossButton"></i>
  </ng-template>
    <ng-container *ngIf="enableNavigation">
      <div *ngIf="!searchResultsCount" class="separator"></div>
      <ng-container *ngIf="searchResultsCount > 0 && !empty">
        <div class="separator"></div>
        <div class="match">{{searchCounterIndex + 1}} of {{searchResultsCount}}</div>
        <div class="separator"></div>
      </ng-container>
      <i class="icon-button al-icon al-ico-ico-chevron-up"
         [class.disabled]="empty || searchCounterIndex < 1"
         (click)="findNext(true)"></i>
      <i class="icon-button al-icon al-ico-ico-chevron-down"
         [class.disabled]="empty || searchCounterIndex + 1 === searchResultsCount || !searchResultsCount"
         (click)="findNext()"></i>
    </ng-container>
  <ng-template #noIcon>
    <ng-content></ng-content>
  </ng-template>
  </span>
</span>

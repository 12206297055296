<sm-dialog-template [iconClass]="'al-icon ' + (extend ? 'al-ico-extend' : 'al-ico-clone')" [header]="header">
  <div *ngIf="!extend; else extendMessage" class="reference">
    A draft copy of <b *ngIf="reference"
                       [smTooltip]="reference.length > 80 ? reference : undefined">{{reference.length > 80 ? (reference | slice:0:77) + '...' : reference }}</b>
    will be created.
  </div>
  <ng-template #extendMessage>
    <div class="reference">
      A draft copy of <b *ngIf="reference" [smTooltip]="reference.length > 80 ? reference : undefined">{{reference.length > 80 ? (reference | slice:0:77) + '...' : reference }}</b> will be created,
      with its input model set to the output model of <b *ngIf="reference">{{reference}}</b>.
    </div>
  </ng-template>
  <form #cloneForm="ngForm">
    <div class="form-container">
      <mat-form-field class="w-100"
                      appearance="outline"
                      hideRequiredMarker="true"
                      (mousedown)="!isFocused(projectInputRef) && projectInput.value && clear(); projectInputRef.blur(); projectInputRef.focus()">
        <mat-label>Project</mat-label>
        <input matInput type="text"
               autocomplete="off"
               [matAutocomplete]="auto"
               [(ngModel)]="formData.project"
               name="projectName"
               #projectInput="ngModel"
               #projectInputRef
               required
               minlength="3"
               (keydown.enter)="projectInput.control.markAsTouched()"
               smUniqueNameValidator
               [existingNames]="readOnlyProjects$ | async">
        <span matSuffix *ngIf="(!isAutoCompleteOpen) && (!formData.project?.value) && formData.project && !(formData.project | stringIncludedInArray:projectsNames)"
              class="creat-new-suffix">(Create New)</span>
        <i matSuffix class="al-icon sm-md search-icon me-2" [ngClass]="formData.project? 'al-ico-dialog-x pointer' : 'al-ico-search'"
           (click)="formData.project && clear(); projectInputRef.focus()"
           smClickStopPropagation></i>
        <mat-error *ngIf="cloneForm.controls.projectName?.errors?.required">Please provide a Project</mat-error>
        <mat-error *ngIf="cloneForm.controls.projectName?.errors?.minlength">Please provide a name longer than 3 characters</mat-error>
        <mat-error *ngIf="cloneForm.controls.projectName?.errors?.uniqueName">Please provide a different name as this project name is
          taken as an Example project
        </mat-error>
        <mat-error *ngIf="projectInput.touched && projectInput.errors?.emptyName && !projectInput.errors?.minlength">*Project name can't contain only spaces.</mat-error>
      </mat-form-field>
      <mat-autocomplete #auto="matAutocomplete"
                        name="ExperimentProject"
                        class="light-theme"
                        [displayWith]="displayFn"
                        (opened)="setIsAutoCompleteOpen(true)"
                        (closed)="setIsAutoCompleteOpen(false)"
                        autoActiveFirstOption
      >
        <mat-option
          class="item"
          *ngIf="!projectInput?.errors && projects !== null && projectInput.value && !(projectInput.value | stringIncludedInArray:projectsNames)"
          [value]="projectInput.value"
        >"{{projectInput.value.label || projectInput.value}}" <span class="new">(Create New)</span></mat-option>
        <mat-option *ngFor="let project of projects; trackBy: trackByFn" [value]="project" [smTooltip]="project.label" smShowTooltipIfEllipsis>
          {{project.label}}
        </mat-option>
        <div *ngIf="projects === null" style="line-height: 100px; pointer-events: none;">
          <mat-spinner class="m-auto" [diameter]="80" [strokeWidth]="8"></mat-spinner>
        </div>
        <div *ngIf="projects && !noMoreOptions" (smScrollEnd)="!loading && loadMore()" class="text-center">Loading more...</div>
        <mat-option disabled style="height: 0; min-height: 0;"></mat-option> <!-- Empty mat-option, so the autocomplete menu will always pop -->
      </mat-autocomplete>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Name</mat-label>
        <input
          required
          matInput
          autocomplete="off"
          name="ExperimentName"
          [(ngModel)]="formData.name"
        />
        <mat-error *ngIf="cloneForm.controls?.ExperimentName?.errors?.required">Please provide a name</mat-error>
      </mat-form-field>
      <mat-form-field class="w-100" appearance="outline"
                      hideRequiredMarker="true">
        <mat-label>Description</mat-label>
        <textarea
          name="ExperimentComment"
          matInput
          [rows]="7"
          [(ngModel)]="formData.comment"></textarea>
      </mat-form-field>

      <div class="buttons">
        <button cdkFocusInitial (click)="closeDialog(true)" [disabled]="!cloneForm.valid"
                class="btn btn-neon yes-button" data-id="CloneButton" #cloneButton>
          {{extend ? 'EXTEND' : 'CLONE'}}
        </button>
        <button (click)="closeDialog(false)" class="btn btn-outline-neon" data-id="CancelButton">
          CANCEL
        </button>

      </div>
    </div>
  </form>
</sm-dialog-template>
